import React, { useState, useEffect } from 'react';

import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Typography,
    TextField,
    InputAdornment,
    Button,
    Grid,
    CardMedia
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import faqTexts from './faqTexts.json'
import ReactMarkdown from 'react-markdown';

interface ComponentProps {
}

const InfoView: React.FC<ComponentProps> = () => {
    const [selectedHeader, setSelectedHeader] = useState<number>(0)
    const [searchArrray, setSeachArrray] = useState<number[] | null>(null);
    const useSearch = searchArrray;

    function flat() {
        let result: any[] = [];
        faqTexts.forEach(item => {
            result = [...result, ...item.headers]
        })
        return result;
    }

    const flatList = flat();


    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("search", e.target.value)
        let result: number[] = [];
        if (!e.target.value || e.target.value === "") {
            setSeachArrray(null)
            return;
        }
        console.log("searching", e.target.value)
        flatList.forEach((item, index) => {
            if ((item.header.toLowerCase().includes(e.target.value) || item.text.toLowerCase().includes(e.target.value)) && result.length < 6) {
                result = [...result, index]
            }
        })
        console.log(result)
        setSeachArrray(result);
    }

    return (
        <Box
            //onScroll={onScroll}

            sx={{
                overflow: 'auto', display: 'flex', justifyContent: 'center', flex: 1
            }} >

            <Box
                flexDirection={'column'}
                marginTop={2}
                sx={{
                    maxWidth: { xs: "100vw", sm: "1000px" }, 
                }}
            >
                <Box sx={{
                    backgroundColor: '#3A52D10D', 
                    border: '1px solid', 
                    borderColor: '#E6E6E9', 
                    borderRadius: '8px', 
                    padding: { xs: "10px", sm: '48px 96px' }, 
                    }}
                >
                    <Typography
                        fontWeight={500}
                        fontSize={'19px'}
                        textAlign={'center'}
                        marginBottom={2}
                    >
                        Hei, kuinka voimme auttaa?
                    </Typography>
                    <Typography
                        textAlign={'center'}
                        marginBottom={2}
                    >
                        Kirjoita hakusi alle. Voit kysyä mitä tahansa Codex+ -sovellukseen liittyen.
                        Esimerkkihakusanat: "Käyttäjätunnukset", "kieliasetukset", "tekoälyn käyttö", "tietosuoja"
                    </Typography>
                    <TextField
                        sx={{ backgroundColor: 'white' }}
                        fullWidth
                        placeholder="Kirjoita hakusi tähän, esim. 'tietosuojaseloste'"
                        onChange={onSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Grid
                    container
                    spacing={2}
                    marginTop={2}
                >
                    <Grid item xs={12} md={3} lg={3}>
                        {faqTexts.map((item, index) => (
                            <Button
                                key={"button" + index}
                                fullWidth
                                style={{ justifyContent: "flex-start" }}
                                variant={selectedHeader === index ? 'contained' : undefined}
                                onClick={() => { setSelectedHeader(index); setSeachArrray(null); }}
                            >
                                {item.name}
                            </Button>
                        ))
                        }
                    </Grid>
                    <Grid
                        item xs={12} md={9} lg={9}
                        display={'flex'}
                    >
                        <Box
                            display={'flex'} flex={1}
                            flexDirection={'column'}
                            sx={{ backgroundColor: '#FCFCFC', border: '1px solid', borderColor: '#E6E6E9', borderRadius: '8px', }}
                            padding={4}
                        >
                            <Box sx={{ backgroundColor: '#3A52D10D', border: '1px solid', borderColor: '#E6E6E9', borderRadius: '8px', padding: '8px 32px' }}
                                marginBottom={2}
                            >
                                <Typography
                                    fontWeight={500}
                                    fontSize={'19px'}
                                    textAlign={'left'}
                                >
                                    {!useSearch ? faqTexts[selectedHeader].name : "Haun tulokset"}
                                </Typography>
                            </Box>
                            {!useSearch && faqTexts[selectedHeader].headers.map((item, index) => (
                                <Accordion key={"accordion" + index}
                                    sx={{ backgroundColor: '#FCFCFC' }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        {item.header}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ReactMarkdown
                                            components={{
                                                a: ({ node, children, ...props }) => {
                                                    return <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        {...props}
                                                    >
                                                        {children}
                                                    </a>
                                                }
                                            }}
                                        >
                                            {item.text}
                                        </ReactMarkdown>
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                            {useSearch && searchArrray.map((item, index) => (
                                <Accordion key={"accordion" + index}
                                    sx={{ backgroundColor: '#FCFCFC' }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        {flatList[item].header}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {flatList[item].text}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>


                    </Grid>
                </Grid>

                <Box
                    marginTop={2}
                    marginBottom={2}
                    sx={{
                        backgroundColor: '#F8F8F8', 
                        border: '1px solid', 
                        borderColor: '#E6E6E9', 
                        borderRadius: '8px', 
                        padding: { xs: "10px", sm: '48px 96px' }, 
                        maxWidth: { xs: "100vw", sm: '1000px' },  
                    }}
                    >
                    <Typography
                        fontWeight={500}
                        fontSize={'19px'}
                        textAlign={'center'}
                        marginBottom={2}
                    >
                        Johdatus Codex+ -sovellukseen
                    </Typography>
                    <Typography
                        textAlign={'center'}
                        marginBottom={2}
                    >
                        Tämä video tarjoaa sinulle kattavan johdatuksen sovelluksemme pääominaisuuksiin ja kertoo, kuinka voit hyödyntää Codex+ -sovellusta tehokkaasti oikeudellisessa työssäsi. Katso, opi ja ota kaikki irti Codex+ -sovelluksen tarjoamista mahdollisuuksista!
                    </Typography>
                    <CardMedia
                        component='video'
                        //className={classes.media}
                        image={'videos/onboarding.mp4'}
                        //autoPlay
                        controls
                    />
                </Box>
            </Box>
        </Box>
    )

};

export default InfoView;
