import React, { useState, } from "react";
import { 
    Box, 
    TextField, 
    Button, 
    Select, 
    MenuItem, 
    FormControl, 
    Typography, 
} from "@mui/material";
import { 
    AdministrationUserApi, 
    AdministrationUserPasswordResetApi 
} from "../../lib/apiActions";
import Alert from '@mui/material/Alert';

enum userType {
    Lawyer = "Lawyer",
    Entrepreneur = "Entrepreneur",
}

interface ComponentProps {

}

export const CreateUser: React.FC<ComponentProps> = (props) => {
    const [email, setEmail] = useState<string>("");
    const [type, setType] = useState<userType>(userType.Entrepreneur);
    const organization = "Agora Legal Oy";
    const brand = "agora";
    const send_email = true;
    const reason = "onboarding";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [isCreatingUser, setCreatingUser] = useState<boolean>(false);
    const [alert, setAlert] = useState<string | null>(null);

    const validateEmail = (email: string): boolean => {
        return emailRegex.test(email);
    };

    const handleTypeChange = (e: any) => {
        setType(e.target.value);
        console.log("value", e.target.value);
    } 

    const createUserPress = async () => {
        setCreatingUser(true);
        try {
            if (!validateEmail(email)) {
                setAlert("Virheellinen sähköposti");
                return;
            }

            const body = {
                email: email,
                organization: organization,
                brand: brand,
                type: type,
                role_id: [],
            }
            await AdministrationUserApi.createUser(body, null);
            setAlert("Käyttäjä luotu: " + email);
        } catch (error) {
            // console.error('Error creating user:', error);
            setAlert("Virhe: " + error);
        } finally {
            setCreatingUser(false);
        }
    }

    const sendPasswordReset = async () => {
        try {
            if (!validateEmail(email)) {
                setAlert("Virheellinen sähköposti");
                return;
            }

            const body = {
                email: email,
                send_email: send_email,
                reason: reason,
            }
            await AdministrationUserPasswordResetApi.sendPasswordResetLink(body, null);
            setAlert("Salasanan resetointilinkki lähetetty: " + email);
        } catch (error) {
            setAlert("Virhe salasanan resetoinnissa: " + error);
        }
    }

    return (
        <>
            <Box sx={{ 
                width: { xs: "100vw", sm: "70vw", },
                height: "initial", 
                mt: { xs: 3, sm: 5 }, 
            }}>
                <FormControl sx={{ 
                        display: "flex", 
                        flexDirection: { xs: "column", sm: "row" },
                    }}>
                    <TextField label="sähköposti" 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(event.target.value);
                    }}/>
                    <Select
                        value={type}
                        label="type"
                        onChange={handleTypeChange}
                        sx={{
                            width: { xs: "33vw", sm: "10vw", }
                        }}
                        >
                        <MenuItem value={"Lawyer"}>Lawyer</MenuItem>
                        <MenuItem value={"Entrepreneur"}>Entrepreneur</MenuItem>
                    </Select>
                    
                    <Button variant="contained" color="primary" onClick={createUserPress} sx={{ 
                        mt: { xs: 1, sm: 0 }, 
                        mr: { xs: 0, sm: 5 }, 
                        mb: { xs: 1, sm: 0 }, 
                        ml: { xs: 0, sm: 5 }, 
                        width: { xs: "50vw", sm: "initial" }, 
                    }}
                    >Luo käyttäjä</Button>

                    <Button variant="contained" color="primary" onClick={sendPasswordReset} disabled={isCreatingUser} sx={{
                        width: { xs: "75vw", sm: "initial" },
                    }}
                    >Lähetä salasana linkki</Button>

                </FormControl>
                {alert && 
                    <Alert 
                        severity={alert.includes("Virhe") ? "error" : "success"} 
                        onClose={() => {setAlert(null);}}
                    >
                        {alert}
                    </Alert>
                }
            </Box>
        </>
    );
}
