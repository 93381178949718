import React from "react";
import {
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Typography 
} from "@mui/material";

export interface User {
    uuid: string;
    email: string;
    last_login: string;
}

interface Props {
    users: User[] | null;
}

export const ListUsers: React.FC<Props> = ({ users }) => {
    return (
        <TableContainer sx={{ 
            minWidth: { xs: "100vw", sm: "60vw", }, 
            height: { xs: "initial", sm: "50vh", }, 
            overflow: "auto", 
        }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="subtitle1">Sähköposti</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">Aktivoitu</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users && users.length > 0 ? (
                        users.map((user) => (
                            <TableRow key={user.uuid}>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.last_login ? "Kyllä" : "Ei"}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                <Typography variant="body2">No users available</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};