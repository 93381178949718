import { EditNote } from "@mui/icons-material";
import { Box, Button, CSSObject, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, styled, Theme, Tooltip, Typography } from "@mui/material";
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import { authSelector } from "../lib/ducks/authDuck";
import { Chat, chatSelector, deleteAndFetchChats, renameAndFetch } from "../lib/ducks/chatDuck";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useConfirm from "./dialogs/ConfirmDialog";
import { useFormDialog } from "./dialogs/FormDialog";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useCheckMobileScreen from '../utils/useCheckMobileScreen';
import { ClassNames } from "@emotion/react";

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    border: "none"
});


const closedMixin = (theme: Theme, navigationDrawerWidthMin: number): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: navigationDrawerWidthMin,
    border: "none"
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<MyDrawerProps>(
    ({ theme, open, drawerwidth, navigationdrawerwidthmin }) => ({
        width: drawerwidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme, drawerwidth),
            '& .MuiDrawer-paper': openedMixin(theme, drawerwidth),
        }),
        ...(!open && {
            ...closedMixin(theme, navigationdrawerwidthmin),
            '& .MuiDrawer-paper': closedMixin(theme, navigationdrawerwidthmin),
        }),
    }),
);

interface StyledListItemProps {
    index: number,
    open: boolean,
    handleMenuClick: any,
    handleChatChange: any,
    conversation: Chat,
    selectedId: string | null,
    menuOpen: boolean
}

const StyledListItem: React.FC<StyledListItemProps> = ({ index, open, handleMenuClick, handleChatChange, conversation, selectedId, menuOpen }) => {

    const [hoverOn, setHoverOn] = useState(false);
    const [menuClicked, setMenuClicked] = useState(false);
    const selected = selectedId === conversation.id;

    useEffect(() => {
        if (menuOpen === false)
            setMenuClicked(false);
    }, [menuOpen])

    const onMenuClick = (ev: any, id: string) => {
        setMenuClicked(true)
        handleMenuClick(ev, conversation.id)
    }
    return (
        <ListItem key={"StyledListItem" + index} disablePadding 
        sx={{ 
            display: 'block', 
            opacity: open ? 1 : 0,
            borderRadius: "8px",
            backgroundColor: selected ? "#3A52D1" : "transparent",
        }}
            onMouseEnter={() => setHoverOn(true)}
            onMouseLeave={() => setHoverOn(false)}
            secondaryAction={
                (selected || hoverOn || (menuOpen && menuClicked)) &&
                <IconButton sx={{color: "#FFFFFF"}} edge="end" aria-label="edit" onClick={(ev) => onMenuClick(ev, conversation.id)}>
                    <MoreHorizIcon />
                </IconButton>
            }

        >

            <ListItemButton

                sx={{
                    minHeight: 34,
                    justifyContent: open ? 'initial' : 'center',
                    padding: "6px 0px 6px 11px",
                    margin: 0,
                    borderRadius: '8px',
                    width: '100%'
                }}

                selected={selected}
                onClick={() => { handleChatChange(conversation, conversation.id) }}
            //endIcon={<MoreHorizIcon />}

            >
                <Tooltip
                    //title={moment(conversation.updated).format("D.M.YYYY H.mm")}
                    title={conversation.title + ' | ' + moment(conversation.updated).format("D.M.YYYY H.mm")}
                >
                    <ListItemText
                        primaryTypographyProps={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '14px',
                            margin: 0,
                            color: "#FFFFFF",
                        }}

                        primary={conversation.title !== "" ? conversation.title : "Uusi aihe"}
                        //secondary={moment(conversation.updated).format("D.M.YYYY H.mm")}
                        sx={{ margin: 0 }}
                    />
                </Tooltip>
            </ListItemButton>
        </ListItem>
    )

}

interface MyDrawerProps extends DrawerProps {
    navigationdrawerwidthmin: number,
    drawerwidth: number
}

interface ComponentProps {
    navigationDrawerWidthMin: number,
    drawerWidth: number
    handleNewChat: any,
    handleDrawerOpen: any,
    handleDrawerClose: any,
    open: any,
    handleChatChange: any
}

export const ChatDrawer: React.FC<ComponentProps> = ({ navigationDrawerWidthMin, drawerWidth, handleNewChat, handleDrawerOpen, handleDrawerClose, open, handleChatChange }) => {

    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [getConfirmation, ConfirmationDialog] = useConfirm();
    const darkTheme = useAppSelector(authSelector).darkTheme
    const conversations = useAppSelector(chatSelector).conversations
    const selectedId = useAppSelector(chatSelector).selectedChatId
    const [getForm, FormDialog] = useFormDialog();
    const isMobile = useCheckMobileScreen();

    const [menuConv, setMenuConv] = React.useState("");
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, convId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuConv(convId);
    };

    const menuOpen = Boolean(anchorEl);
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        console.log("useeffect selectedChatId", selectedId)
        isMobile && handleDrawerClose();
    }, [selectedId])

    const handleDeleteChat = async (convId: string) => {
        const convName = conversations.find(s => s.id === convId)?.title;
        const result = await getConfirmation({
            title: 'Poistetaanko keskustelu?',
            text: 'Tämä toiminto poistaa keskustelun ' + convName + ' pysyvästi.',
            okText: 'Poista',
            cancelText: 'Peruuta'
        })
        if (result) {
            dispatch(deleteAndFetchChats(convId));
        }
    }

    const handleRenameChat = async (convId: string) => {
        const result = await getForm({ title: 'Uudelleen nimeä aihe?', text: 'Anna aiheelle kuvaava nimi, jotta löydät tärkeät aiheet nopeasti', okText: 'OK', cancelText: 'Peruuta', inputPlaceholder: '' })

        if (result.status) {
            dispatch(renameAndFetch(convId, { title: result.input }))
        }
    }

    const conversationsToday = conversations?.slice().filter(c => moment(c.updated) > moment().startOf('day')).sort((a, b) => (a.updated > b.updated ? -1 : 1))
    const conversationsWeek = conversations?.slice().filter(c => moment(c.updated) < moment().startOf('day') && moment(c.updated) > moment().add(-7, 'days')).sort((a, b) => (a.updated > b.updated ? -1 : 1))
    const conversationsOlder = conversations?.slice().filter(c => moment(c.updated) < moment().add(-7, 'days')).sort((a, b) => (a.updated > b.updated ? -1 : 1))

    const chatDrawerContents = () => {
        return(
        <>
            <Box sx={{ 
                width: { xs: "100vw", sm: drawerWidth - navigationDrawerWidthMin } + 'px', 
                height: "100%", 
                display: 'flex', 
                flexDirection: 'column', 
                marginLeft: navigationDrawerWidthMin + 'px', 
                backgroundColor: "#16171B", // fix: take from theme
            }} >
                <Box sx={{ display: { xs: "none", sm: 'flex' }, flexDirection: 'row', height: '64px' }} padding={2} justifyContent={'space-between'}>
                    <Button
                        onClick={() => { handleNewChat(); handleDrawerOpen() }}
                        sx={{
                            ...{ color: darkTheme ? 'white' : '#FFFFFF' },
                            textTransform: "none",
                            fontWeight: "600",
                            letterSpacing: "0.01em",
                            backgroundColor: 'transparent'
                        }}
                        startIcon={<EditNote />}
                    >
                        Uusi keskustelu
                    </Button>
                    <IconButton onClick={() => { handleDrawerClose() }}>
                        <ChevronLeftIcon sx={{ color: "#FFFFFF"}} />
                    </IconButton>
                </Box>

                <List
                    sx={{
                        overflow: 'auto',
                        px: '16px'
                    }}
                >
                    <ListItem disablePadding sx={{ display: 'block', opacity: open ? 1 : 0 }}>
                        <Typography
                            fontSize={'12px'}
                            fontWeight={600}
                            color={'#FFFFFF'}
                        >
                            Tänään
                        </Typography>
                    </ListItem>
                    {conversationsToday.map((conversation: Chat, index: number) => (
                        <StyledListItem
                            key={"conversationsToday" + index}
                            open={open}
                            index={index}
                            conversation={conversation}
                            handleChatChange={handleChatChange}
                            handleMenuClick={handleMenuClick}
                            selectedId={selectedId}
                            menuOpen={menuOpen}
                        />
                    ))}
                    <ListItem disablePadding sx={{ display: 'block', opacity: open ? 1 : 0, marginTop: 2 }}>
                        <Typography
                            fontSize={'12px'}
                            fontWeight={600}
                            color={'#FFFFFF'}
                        >
                            Edelliset 7 päivää
                        </Typography>
                    </ListItem>
                    {conversationsWeek.map((conversation: Chat, index: number) => (
                        <StyledListItem
                            key={"conversationsWeek" + index}
                            open={open}
                            index={index}
                            conversation={conversation}
                            handleChatChange={handleChatChange}
                            handleMenuClick={handleMenuClick}
                            selectedId={selectedId}
                            menuOpen={menuOpen}
                        />
                    ))}
                    <ListItem disablePadding sx={{ display: 'block', opacity: open ? 1 : 0, marginTop: 2 }}>

                        <Typography
                            fontSize={'12px'}
                            fontWeight={600}
                            color={'#FFFFFF'}
                        >
                            Vanhemmat
                        </Typography>
                    </ListItem>
                    {conversationsOlder.map((conversation: Chat, index: number) => (
                        <StyledListItem
                            key={"conversationsOlder" + index}
                            open={open}
                            index={index}
                            conversation={conversation}
                            handleChatChange={handleChatChange}
                            handleMenuClick={handleMenuClick}
                            selectedId={selectedId}
                            menuOpen={menuOpen}
                        />
                    ))}
                </List>
                </Box>
                <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                PaperProps={{ sx: { background: '#FCFCFC' } }}
                >
                <MenuItem onClick={() => { handleRenameChat(menuConv); handleMenuClose() }}>
                <ListItemIcon sx={{ paddingLeft: '4px' }}>
                    <EditIcon sx={{ color: '#2C2F33' }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{
                    color: '#2C2F33',
                }}>Nimeä uudelleen</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { handleDeleteChat(menuConv); handleMenuClose() }}>
                <ListItemIcon >
                    <DeleteIcon sx={{ color: '#FB3332' }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{
                    color: '#FB3332',
                }} >Poista</ListItemText>
                </MenuItem>
                </Menu>
        </>
        );
    }

    return (
        <>
            <FormDialog />
            <ConfirmationDialog />
            {!isMobile && (
                <Drawer navigationdrawerwidthmin={navigationDrawerWidthMin} drawerwidth={drawerWidth} variant="permanent" open={open} sx={{ borderRight: '0px', }} >
                    {chatDrawerContents()}
                </Drawer>)}
            {isMobile && (
                <>
                {chatDrawerContents()}
                </>
            )}
                
        </>

    );
};