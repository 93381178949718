import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UserInfo } from "../../lib/ducks/authDuck";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import { Box, TextField, Button, Typography, Grid } from "@mui/material";
import { CustomerContactApi } from "../../lib/apiActions";

interface CustomerContactFormProps {
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
  text?: string;
}

interface CustomerContactFormDialogProps {
  isOpen: boolean;
  handleClickClose: () => void;
  user?: UserInfo | null;
}

export default function CustomerContactFormDialog({
  isOpen,
  handleClickClose,
  user,
}: CustomerContactFormDialogProps) {
  const [formState, setFormState] = useState<CustomerContactFormProps>({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email: user?.email || "",
    phone_number: user?.phone_number || "",
    text: "",
  });

  function handleCustomerContact(e: any) {
    e.preventDefault();
    // console.log("Customer Contacted");
    // console.log(formState);
    CustomerContactApi.contact(formState, null);
    onClose();
  }

  function onClose() {
    // console.log("onClose");
    setFormState({
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone_number: user?.phone_number || "",
      text: "",
    });
    // console.log(formState);
    handleClickClose();
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          component: "form",
          onSubmit: (e: any) => {
            handleCustomerContact(e);
          },
          sx: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            gap: "16px",
            maxWidth: { xs: "90vw", sm: "900px"},
            maxHeight: { xs: "95vh", sm: "540px" },
            background: "#FCFCFC",
            border: "1px solid #E6E6E9",
            borderRadius: "8px",
            flex: "none",
            order: "1",
            alignSelf: "stretch",
            flexGrow: "0",
          },
        }}
      >
        <DialogTitle
          sx={{
            alignSelf: "stretch",
          }}
        >
          Juristin yhteydenottopyyntölomake
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Jätä yhteystietosi, niin juristi ottaa sinuun yhteyttä.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={formState.first_name}
                autoFocus
                required
                margin="dense"
                id="first_name"
                name="first_name"
                label="Etunimi"
                type="text"
                fullWidth
                variant="outlined"
                onChange={onChangeHandler}
                sx={{ mt: { xs: 3, sm: 0 }, }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={formState.last_name}
                required
                margin="dense"
                id="last_name"
                name="last_name"
                label="Sukunimi"
                type="text"
                fullWidth
                variant="outlined"
                onChange={onChangeHandler}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={formState.email}
                autoFocus
                required
                margin="dense"
                id="email"
                name="email"
                label="Sähköposti"
                type="email"
                fullWidth
                variant="outlined"
                onChange={onChangeHandler}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={formState.phone_number}
                margin="dense"
                id="phoner_number"
                name="phone_number"
                label="Puhelinnumero"
                type="text"
                fullWidth
                variant="outlined"
                onChange={onChangeHandler}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="text"
                name="text"
                label="Viesti"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                onChange={onChangeHandler}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Box
          sx={{
            minWidth: "100%",
          }}
        >
          <DialogActions sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, 
          }}>
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
                width: "89px",
                height: "43px",
                border: "1px solid #3A52D1",
                borderRadius: "8px",
                flex: "none",
                order: { xs: 2, sm: 1 },
                flexGrow: 0,
                color: "#3A52D1",
                fontWeight: "bold",
              }}
            >
              Peruuta
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
                width: "231px",
                height: "43px",
                backgroundColor: "#3A52D1",
                borderRadius: "8px",
                flex: "none",
                order: { xs: 1, sm: 2 },
                flexGrow: 0,
                color: "#FFFFFF",
                fontWeight: "bold",
              }}
            >
              Lähetä yhteydenottopyyntö
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
