import { Box, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { ListResult } from "../components/ListView/ListResult"
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from "../lib/hook";
import { fetchSearch, listSelector, SearchRequest } from "../lib/ducks/listDuck";
import { SourcesTables } from "../lib/ducks/chatDuck";



export const ListView = () => {
    const isLoading = useAppSelector(listSelector).isLoading;
    const isInitial = useAppSelector(listSelector).initial;

    const dispatch = useAppDispatch()
    const [sourceIndexes, setSourceIndexes] = useState<number[]>([]);
    const [sourceText, setSourceText] = useState<string[]>([]);
    const [searchText, setSearchText] = useState("");

    const handleSearch = () => {
        const sources = SourcesTables.filter((s, index) => sourceIndexes.some(i => i === index)).flatMap(y => y.names)
        const body: SearchRequest = { message: searchText, exclude_ids: [], source_tables: sources }
        dispatch(fetchSearch(body))
    }

    const handleChange = (event: SelectChangeEvent<typeof sourceText>) => {
        const {
            target: { value },
        } = event;
        setSourceIndexes((value as string[]).map(Number))
        setSourceText(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Box
            display="flex"
            //justifyContent="center"
            flexDirection={'column'}
            alignSelf={'center'}
            minHeight={'407px'}
            sx={{ width: { xs: "100vw", sm: "1011px" }, backgroundColor: '#FCFCFC', borderStyle: 'solid', border: 1, borderRadius: '8px', borderColor: '#E6E6E9' }}
            padding={'32px'}
            maxHeight={'90%'}
        >
            {isInitial &&
                <>
                    <Typography align="center" fontSize={'18px'} fontWeight={600} margin={2}>
                        Hae ja tallenna olennaiset juridiset lähteet
                    </Typography>
                    <Typography align="center" fontSize={'16px'} fontWeight={400} margin={2} marginTop={0}>
                        Codex+ listausominaisuus mahdollistaa tehokkaan haun tärkeimmistä juridisista lähteistä,
                        kuten KKO- ja KHO-päätöksistä. Kirjoita avainsanat ja selaa hakutuloksia – tallenna ja järjestä olennaisimmat tiedot vaivattomasti.
                    </Typography>
                </>
            }
            <Box
                maxWidth={'300px'}
                marginBottom={4}
            >
                <Typography fontSize={'16px'} fontWeight={'fontWeightBold'} marginY={2}>
                    Filtterit
                </Typography>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Valitse lähde</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sourceText}
                        label="Valitse lähde"
                        onChange={handleChange}
                        multiple
                    >
                        {SourcesTables.map((source, index) => (
                            <MenuItem key={"sourceitem" + index} value={index}>{source.displayName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>


            <TextField
                label="Hae avainsanoilla, kuten ‘työoikeus’, ‘perintö’..."
                id="outlined-start-adornment"
                value={searchText}
                onChange={(ev) => setSearchText(ev.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            onClick={handleSearch}
                            edge="end"
                        >
                            <SearchIcon />
                        </IconButton></InputAdornment>,
                }}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter' && !ev.shiftKey) {
                        // Do code here
                        ev.preventDefault();
                        handleSearch();
                    }
                }}
            />


            {isLoading &&
                <CircularProgress sx={{ marginTop: 2, alignSelf: 'center' }} />
            }

            {!isInitial &&
                <ListResult />
            }

        </Box>
    )
}