import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
const createPromise = () => {
    let resolver;
    return [new Promise((resolve, reject) => {
        resolver = resolve;
    }), resolver];
};
export const useFormDialog = (): [any, any] => {
    const [open, setOpen] = useState(false);
    const [resolver, setResolver] = useState<any>({ resolver: null });
    const [label, setLabel] = useState({ title: '', text: '', okText: '', cancelText: '', inputPlaceholder: '', descriptionField: '' });
    useEffect(() => {
        //console.log("useFormDialog rendering");
    }, []);
    //params:{title,text, okText,cancelText}
    const getForm = async (params: any) => {
        setLabel(params);
        setOpen(true);
        const [promise, resolve] = await createPromise();
        setResolver({ resolve });
        return promise;
    };
    const onClick = async (status: boolean, input: string, input2: string) => {
        setOpen(false);
        resolver.resolve({ status, input, input2 });
    };

    const FormDialog = () => {
        const [input, setInput] = useState('');
        const [input2, setInput2] = useState('');
        useEffect(() => {
            setInput(label.inputPlaceholder ?? '');
            setInput2('');
        }, []);

        return (
            <Dialog
                keepMounted
                open={open}
                onClose={() => onClick(false, '', '')}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                sx={{
                    minWidth: { xs: "90vw", sm: '500px' }, 
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {label.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {label.text}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        //placeholder={label.inputPlaceholder}
                        required
                        margin="dense"
                        //id="formname"
                        fullWidth
                        variant="standard"
                        value={input}
                        onChange={(event) => {
                            setInput(event.target.value);
                        }}
                    />
                    {label.descriptionField &&
                        <TextField
                            multiline
                            rows={2}
                            margin="dense"
                            //id="description"
                            fullWidth
                            label={label.descriptionField}
                            value={input2}
                            onChange={(event) => {
                                setInput2(event.target.value);
                            }}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClick(false, '', '')}>{label.cancelText}</Button>
                    <Button onClick={() => onClick(true, input, input2)} >
                        {label.okText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    return [getForm, FormDialog];
};